import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

const HelmetHeader = ({ pageConfig }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageConfig.title}</title>
      {pageConfig.description && <meta name="description" content={pageConfig.description} />}
    </Helmet>
  )
}

HelmetHeader.propTypes = {
  pageConfig: PropTypes.object
}

export default HelmetHeader
