function Fanpage() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.66699 10C2.66699 10 3.33366 9.33337 5.33366 9.33337C7.33366 9.33337 8.66699 10.6667 10.667 10.6667C12.667 10.6667 13.3337 10 13.3337 10V2.00004C13.3337 2.00004 12.667 2.66671 10.667 2.66671C8.66699 2.66671 7.33366 1.33337 5.33366 1.33337C3.33366 1.33337 2.66699 2.00004 2.66699 2.00004V10Z"
        stroke="#9D9D9C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66699 14.6667V10"
        stroke="#9D9D9C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Fanpage
