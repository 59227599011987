import logo from 'static/images/logo.svg'
import UserTop from 'components/User/UserTop'
import { Link } from 'react-router-dom'
import styles from './header.module.css'

function Header() {
  return (
    <header className={styles.header}>
      <nav className={styles.headerNav}>
        <Link to="/" className={styles.headerNavLogo}>
          <img src={logo} alt="Powiadomienia Facebook - Biore.pl" />
        </Link>
        <UserTop />
      </nav>
    </header>
  )
}

export default Header
