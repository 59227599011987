import { useCallback, useContext, useEffect } from 'react'
import Context from 'util/context'
import fbLogo from '../static/images/fb.svg'
import styles from 'components/Login/login.module.css'
import { auth } from 'util/api'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getToken } from './storageData'

const scopes = process.env.REACT_APP_FACEBOOK_APP_PERMISSIONS
const apiVersion = process.env.REACT_APP_FACEBOOK_APP_SDK_VERSION
const appId = process.env.REACT_APP_FACEBOOK_APP_ID
const redirectUri = process.env.REACT_APP_FACEBOOK_APP_REDIRECT_URI

const REGISTRATION_SUCCESS_MSG = 'Zalogowano pomyślnie!'

export const LoginButton = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const oauthCode = queryParams.get('code')
  const state = queryParams.get('state')
  const oauthErrorCode = queryParams.get('error_code')
  const oauthErrorMsg = queryParams.get('error_message')
  const { setIsLoading, isFbReloadAction, isLoading, setForceRefresh } = useContext(Context)
  const navigate = useNavigate()

  const getAccessToken = useCallback(async () => {
    try {
      if (isLoading || getToken()) {
        return
      }

      setIsLoading(true)
      await auth(oauthCode, !!state)
      toast.success(REGISTRATION_SUCCESS_MSG)
      setIsLoading(false)
      setForceRefresh(true)
      navigate('/')
    } catch (e) {
      setIsLoading(false)
      process.env.NODE_ENV === 'development' && console.error(e)
      toast.error(<div dangerouslySetInnerHTML={{ __html: e.message }}></div>)
    }
  }, [])

  useEffect(() => {
    if (oauthCode) {
      getAccessToken()
    } else if (oauthErrorCode) {
      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html: `Wystąpił błąd logowania FB. Skontaktuj się z supportem. <small>Kod błędu: ${oauthErrorCode}. ${oauthErrorMsg}</small>`
          }}></div>
      )
    }

    return () => {}
  }, [])

  const login = useCallback(() => {
    let href = `https://www.facebook.com/${apiVersion}/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&scope=${scopes}`
    if (isFbReloadAction) {
      href += '&state=reload'
    }

    location.href = href
  }, [])

  return (
    <button onClick={login} className={styles.loginBoxBtn}>
      <img src={fbLogo} alt="Facebook" />
      Zaloguj się przez Facebook
    </button>
  )
}
