export function initFacebookSdk(callback) {
  return new Promise(() => {
    window.fbAsyncInit = function () {
      const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID
      const sdkVersion = process.env.REACT_APP_FACEBOOK_APP_SDK_VERSION

      window.FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: sdkVersion
      })

      callback()
    }

    // load facebook sdk script
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js'
      //js.src = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat/debug.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-chat-script')
  })
}
