import React, { useContext, useMemo } from 'react'
import styles from './footer.module.css'
import Context from 'util/context'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

function Footer() {
  const { canSubscribe, user } = useContext(Context)
  const location = useLocation()

  const footerClass = useMemo(() => {
    return canSubscribe && location.pathname === '/' ? styles.footerSubscribers : styles.footer
  }, [canSubscribe, user, location])

  return (
    <footer className={footerClass}>
      <div className={styles.footerContainer}>
        <ul className={styles.footerList}>
          <li>
            <Link to={'/privacy-policy'}>Polityka prywatności</Link>
          </li>
          <li>
            <Link to={'/terms'}>Regulamin</Link>
          </li>
          <li>
            <Link to={'/faq-dla-klientow'}>FAQ Klienci</Link>
          </li>
          <li>
            <Link to={'/faq-dla-sprzedawcow'}>FAQ Sprzedawcy</Link>
          </li>
        </ul>

        <span className={styles.footerCopyrights}>
          Aplikacja stworzona przez platformę sprzedażową Biore.pl
        </span>
      </div>
    </footer>
  )
}

export default Footer
