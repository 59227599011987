export const faqItems = [
  {
    title: 'Czym są powiadomienia.biore.pl?',
    content:
      "<b>Powiadomienia.biore.pl</b> to prosty i intuicyjny program do newsletterów, który umożliwia sprzedawcom szybkie dotarcie z informacją o transmisji online lub innym ważnym wydarzeniu do potencjalnych klientów. Aplikacja zbiera listę subskrybentów danego fanpage'a na Facebooku, do których sprzedawca może wysłać wiadomość wyświetlaną w Messengerze. Z perspektywy klientów są to zwykłe powiadomienia, które w każdej chwili można włączyć lub wyłączyć."
  },
  {
    title: 'Po co powstała aplikacja do powiadomień biore.pl?',
    content:
      'Nasze <b>narzędzie do powiadomień</b> powstało po to, aby klienci nie musieli pamiętać o transmisjach online ulubionych sprzedawców. To także ukłon w stronę przedsiębiorców, którzy dzięki aplikacji mogą rozwijać swojego fanpage’a, poprawiając wyniki sprzedaży.  Aplikacja znacznie ułatwia pracę, ponieważ nie każdy klient śledzi na bieżąco komunikaty umieszczane w social mediach. Dzięki powiadomieniu w Messengerze można w szybki sposób dotrzeć do konkretnej grupy odbiorców z wiadomością, nie martwiąc się o brak reakcji ze strony zainteresowanych.'
  },
  {
    title: 'Jak korzystać z programu do newslettera?',
    content: `Korzystanie z <b>programu do newslettera biore.pl</b> jest bajecznie proste. Sprzedawca loguje się do systemu za pośrednictwem Facebooka, akceptuje wymagane zgody, a następnie uruchamia narzędzie na wskazanym fanpage’u za pomocą przycisku “Aktywuj”. W pierwszym widoku znajduje się również przycisk “Włącz-Wyłącz”, który służy do tymczasowego wyłączenia narzędzia (bez konieczności usuwania  całej bazy zebranych użytkowników). Wybranie tej opcji będzie skutkowało brakiem aktualizacji listy subskrybentów oraz niemożnością podzielenia się z nimi umieszczonymi na późniejszym etapie informacjami. Należy pamiętać więc o tym, że przed przejściem do kolejnego widoku przycisk powinien podświetlać się na zielono.
    <br /><br />
    Kolejnym krokiem jest przeniesienie się do widoku drugiego za pomocą przycisku “Przejdź do Subskrybentów”. Znajdziemy tam listę osób, które zapisały się do newslettera biore.pl dedykowanego konkretnemu fanpage’owi. Widok daje możliwość wyszukiwania konkretnych subskrybentów oraz ich usuwania. Można również wygenerować plik XLS/CSV z listą osób i pobrać go na komputer.
    <br /><br />
    <b>Program do powiadomień na Facebooku</b> posiada również widok trzeci, do którego przechodzimy poprzez przycisk “Wyślij powiadomienie”. To w tym miejscu sprzedawca wpisuje treść wiadomości, którą chce wysłać potencjalnym klientom, decydując się na jedną z dwóch możliwości. W zależności od charakteru informacji może ona zawierać do 2000 znaków lub mieć formę krótkiej notatki do 640 znaków z dedykowanym przyciskiem przenoszącym w wybrane przez sprzedawcę miejsce. W przypadku wiadomości z buttonem zachęcającym do wykonania konkretnego działania należy pamiętać o poprawnym wpisaniu linku, który może przenosić do: rozpoczętego właśnie live’u, sekcji wideo, fanpage'a czy sklepu zewnętrznego. `
  },
  {
    title: 'Jak zapisać się do newslettera konkretnego sprzedawcy?',
    content: `Zapisanie się do newslettera zarządzanego przez biore.pl odbywa się w dwóch krokach. Trzecim jest odebranie wiadomości od sprzedawcy. 
      <br /><br />
      <b>Instrukcja zapisu do powiadomienia.biore.pl</b>
      <br />
      1. Wejdź na fanpage wybranego sprzedawcy na Facebooku.<br />
      2. Umieść komendę “biore start” pod dowolnym postem, jeśli chcesz zacząć otrzymywać powiadomienia.<br />
      3. Ciesz się powiadomieniami od ulubionych fanpage’y.<br />
      <br />
      Wypisanie się z newslettera odbywa się analogicznie do zapisów poprzez umieszczenie komendy “biore stop” pod dowolnym postem na fanpage’u sprzedawcy. Co ważne, nie musisz mieć polubionej danej strony, aby otrzymywać powiadomienia w Messengerze. Dzięki ograniczeniom nałożonym na sprzedawców - wysyłanie powiadomień jeden raz na 4h - unikniesz też spamu, z którym zapewne notorycznie spotykasz się na mailu.`
  },
  {
    title: 'Jakie są benefity korzystania z powiadomienia.biore.pl?',
    content:
      'Nasza aplikacja jest przede wszystkim <b>narzędziem do wysyłania powiadomień na Facebooku</b>, ale pomaga także zarządzać sprzedażą. Mając listę osób, które prawdopodobnie wezmą udział w live na Facebooku, można w łatwy sposób wypromować tydzień wyprzedaży, kody promocyjne czy darmową wysyłkę. Na tym jednak nie koniec, bo sam fakt otrzymywania “alertów zakupowych” wtedy, kiedy akurat ma się na to przestrzeń, jest czymś o niebo lepszym od cyklicznych newsletterów przesyłanych drogą mailową. Niewątpliwym atutem naszej aplikacji, która obsługuje <b>powiadomienia Facebook</b>, jest również prosta forma, którą pokochają zarówno sprzedawcy, jak i subskrybenci. Każdy w obecnych czasach korzysta bowiem z Messengera, traktując go jako podstawowe narzędzie do komunikacji.'
  }
]
