import subscriptionsList from '../../static/images/tworzenie-list-subskrypcyjnych.svg'
import ownMessages from '../../static/images/wysylanie-wlasnych-wiadomosci.svg'
import notificationsManagement from '../../static/images/zarzadzanie-i-edycja-powiadomieniami.svg'
import styles from './login.module.css'
import { LoginButton } from 'util/fbLogin'
import { useEffect, useState } from 'react'
import { getToken } from '../../util/storageData'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import DefaultLoginForm from './DefaultLoginForm'
import Faq from '../Faq/Faq'
import { useMediaQuery } from 'react-responsive'
import HelmetHeader, { LOGIN_PAGE } from '../HelmetHeader'
import faqStyles from '../Faq/faq.module.css'
import { faqItems } from './Login.constants'

function Login() {
  const navigate = useNavigate()
  const token = getToken()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

  const [isLoginModalVisible, setLoginModalVisible] = useState(false)

  const queryParams = new URLSearchParams(window.location.search)
  const noPermissionToLoginViaBiore = queryParams.get('no-permission')

  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [])

  if (token) {
    return null
  }

  return (
    <main className={styles.homeLogin}>
      <HelmetHeader pageConfig={LOGIN_PAGE} />

      <div className="container">
        <div className={styles.homeLoginInner}>
          <h1 className={styles.hiddenHeader}>Program do newsletterów na Messengerze</h1>
          <div
            className={classNames(
              styles.loginBox,
              noPermissionToLoginViaBiore && styles.loginBoxExtendedPadding
            )}>
            <span className={styles.loginBoxTitle}>Zaloguj się</span>
            <LoginButton />

            {!noPermissionToLoginViaBiore && (
              <span className={styles.loginBoxSecondary} onClick={() => setLoginModalVisible(true)}>
                Zaloguj przez biore.pl
              </span>
            )}
          </div>
          <h2 className={styles.registerAdvantagesTitle}>Otwórz się na nowe możliwości</h2>
          <div className="row">
            <div className={`${styles.registerAdvantagesSingleWrapper} col-lg-4`}>
              <div className={styles.registerAdvantagesSingle}>
                <img src={subscriptionsList} alt="Lista subskrybentów" />
                <span>Twórz listy subskrybentów</span>
              </div>
            </div>
            <div className={`${styles.registerAdvantagesSingleWrapper} col-lg-4`}>
              <div className={styles.registerAdvantagesSingle}>
                <img src={ownMessages} alt="Wysyłaj własne wiadomości" />
                <span>
                  Wysyłaj własne <br /> wiadomości tekstowe
                </span>
              </div>
            </div>
            <div className={`${styles.registerAdvantagesSingleWrapper} col-lg-4`}>
              <div className={styles.registerAdvantagesSingle}>
                <img src={notificationsManagement} alt="Zarządzaj powiadomieniami" />
                <span>
                  Zarządzaj <br /> swoimi powiadomieniami
                </span>
              </div>
            </div>
          </div>

          <h2 className={classNames(faqStyles.faqAccordionTitle, styles.registerAdvantagesTitle)}>
            Najczęściej zadawane pytania
          </h2>
          <Faq
            items={faqItems}
            withTwoColumns={!isTabletOrMobile}
            listContainerClassName={styles.listContainer}
          />
        </div>
      </div>

      <DefaultLoginForm
        isOpen={isLoginModalVisible}
        closeModal={() => setLoginModalVisible(false)}
      />
    </main>
  )
}

export default Login
