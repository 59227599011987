export const LOGIN_PAGE = {
  title: 'Program do newsletterów na Messengerze - Powiadomienia Biore.pl',
  description:
    'Wysyłaj powiadomienia do swoich Klientów na Messengerze w prosty sposób. Twórz bazę odbiorców i zwiększaj swoje zasięgi na Facebooku. Sprawdź nasz innowacyjny program.'
}

export const SELLERS_FAQ_PAGE = {
  title: 'Jak stworzyć newsletter na Messengerze | FAQ dla Sprzedawców',
  description:
    'W tym miejscu znajdziesz wszystkie odpowiedzi na pytania, dotyczące naszego innowacyjnego programu Powiadomienia Biore.pl. Skorzystaj już dziś'
}

export const CLIENTS_FAQ_PAGE = {
  title: 'Jak zapisać się do newslettera na Messengerze | FAQ dla Subskrybentów',
  description:
    'Chcesz dostawać powiadomienia na Messengerze od swojego ulubionego sklepu? Sprawdź jak zapisać się na newsletter i bądź na bieżąco.'
}

export const AUTH_ERROR_PAGE = {
  title: 'Błąd autoryzacji - Powiadomienia Biore.pl'
}

export const NO_MATCH_PAGE = {
  title: '404 - Strony nie odnaleziono - Powiadomienia Biore.pl'
}

export const NOTIFICATIONS_PAGE = {
  title: 'Wysyłka powiadomień - Powiadomienia Biore.pl'
}

export const THANK_YOU_PAGE = {
  title: 'Dziękujemy - Powiadomienia Biore.pl'
}

export const SETTINGS_PAGE = {
  title: 'Ustawienia - Powiadomienia Biore.pl'
}

export const HELP_PAGE = {
  title: 'Pomoc - Powiadomienia Biore.pl'
}

export const PRIVACY_POLICY_PAGE = {
  title: 'Polityka prywatności - Powiadomienia Biore.pl'
}

export const RULES_PAGE = {
  title: 'Regulamin - Powiadomienia Biore.pl'
}

export const SUBSCRIBERS_PAGE = {
  title: 'Lista subskrybentów - Powiadomienia Biore.pl'
}
