function ProfileStatus() {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.6665 14.25C3.6665 14.25 4.58317 13.3334 7.33317 13.3334C10.0832 13.3334 11.9165 15.1667 14.6665 15.1667C17.4165 15.1667 18.3332 14.25 18.3332 14.25V3.25004C18.3332 3.25004 17.4165 4.16671 14.6665 4.16671C11.9165 4.16671 10.0832 2.33337 7.33317 2.33337C4.58317 2.33337 3.6665 3.25004 3.6665 3.25004V14.25Z"
        stroke="#16C568"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.6665 20.6667V14.25"
        stroke="#16C568"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ProfileStatus
