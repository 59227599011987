import React, { useEffect } from 'react'
import './css/App.css'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Main from 'components/Main'
import WebFont from 'webfontloader'
import { BrowserRouter } from 'react-router-dom'
import TagManager from 'react-gtm-module'

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Quicksand:500n,600n,700n']
      }
    })

    const gtmId = process.env.REACT_APP_GTM_ID
    if (gtmId) {
      TagManager.initialize({ gtmId })
    }
  }, [])

  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Main />
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App
