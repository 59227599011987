import { useContext } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import Modal from 'react-modal'
import Input from '../Input/Input'
import iconClose from '../../static/images/close-btn-icon.svg'
import { loginViaBiore } from 'util/api'
import { toast } from 'react-toastify'
import { getToken } from 'util/storageData'
import Context from 'util/context'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import styles from './defaultLoginForm.module.css'

const REQUIRED_ERROR = 'To pole jest wymagane'
const INVALID_EMAIL = 'Niepoprawny adres e-mail'

export const FORM_SCHEMA = z.object({
  email: z.string().email({ message: INVALID_EMAIL }).min(1, {
    message: REQUIRED_ERROR
  }),
  password: z.string().min(1, {
    message: REQUIRED_ERROR
  })
})

const customStyles = {
  content: {
    border: 0,
    overflow: 'auto',
    borderRadius: '30px',
    maxWidth: '535px',
    minHeight: '320px',
    padding: '50px 36px 36px 36px',
    margin: 'auto',
    position: 'static',
    flex: 1
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    alignItems: 'center',
    zIndex: 999,
    padding: '0 15px'
  }
}

Modal.setAppElement('body')

const DefaultLoginForm = ({ isOpen = false, closeModal }) => {
  const { setIsLoading, isLoading, setForceRefresh } = useContext(Context)
  const navigate = useNavigate()

  const { handleSubmit, control, reset } = useForm({
    defaultValues: { email: '', password: '' },
    resolver: zodResolver(FORM_SCHEMA)
  })

  const onCloseModal = () => {
    reset()
    closeModal?.()
  }

  const onSubmit = async (data) => {
    try {
      if (isLoading || getToken()) {
        return
      }

      setIsLoading(true)
      await loginViaBiore(data)
      setIsLoading(false)
      setForceRefresh(true)
      navigate('/')
    } catch (e) {
      setIsLoading(false)
      toast.error(<div dangerouslySetInnerHTML={{ __html: e.message }}></div>)
    }
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onCloseModal} style={customStyles}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <button type="button" onClick={onCloseModal} className={styles.confirmDeletePopupCloseBtn}>
          <img src={iconClose} />
        </button>

        <span className={styles.modalHeader}>Zaloguj się</span>

        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              hasError={fieldState.invalid}
              placeholder="Adres e-mail"
              type="text"
              infoMessage={fieldState.error?.message}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              hasError={fieldState.invalid}
              placeholder="Hasło"
              type="password"
              infoMessage={fieldState.error?.message}
            />
          )}
        />

        <button type="submit" className={styles.loginBoxBtn}>
          Zaloguj się
        </button>
      </form>
    </Modal>
  )
}

DefaultLoginForm.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func
}

export default DefaultLoginForm
