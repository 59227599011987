import * as ACTION_TYPES from './action_type'

export const setIsLoading = (data) => {
  return {
    type: ACTION_TYPES.SET_IS_LOADING,
    isLoading: data
  }
}

export const setUser = (data) => {
  return {
    type: ACTION_TYPES.SET_USER,
    user: data
  }
}

export const setAuthError = (data) => {
  return {
    type: ACTION_TYPES.SET_AUTH_ERROR,
    authError: data
  }
}
