import styles from './authError.module.css'
import { LoginButton } from 'util/fbLogin'
import errorImage from 'static/images/error-image.svg'
import HelmetHeader, { AUTH_ERROR_PAGE } from 'components/HelmetHeader'

function AuthError() {
  return (
    <div className={styles.errorPageMain}>
      <HelmetHeader pageConfig={AUTH_ERROR_PAGE} />

      <div className="container">
        <div className={styles.errorPageMainBox}>
          <img src={errorImage} />
          <strong className={styles.errorPageMainBoxTitle}>Brak uprawnień</strong>
          <p className={styles.errorPageMainBoxText}>
            Zaloguj się ponownie, aby zarządzać powiadomieniami.
          </p>
          <LoginButton />
        </div>
      </div>
    </div>
  )
}

export default AuthError
