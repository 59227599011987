function Help() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00004 13.6667C3.31982 13.6626 0.337416 10.6802 0.333374 6.99999V6.86666C0.406662 3.20301 3.4231 0.285296 7.08716 0.333912C10.7512 0.382528 13.6892 3.37925 13.6652 7.04355C13.6413 10.7079 10.6644 13.6659 7.00004 13.6667ZM6.98937 12.3333H7.00004C9.94452 12.3304 12.3295 9.9418 12.328 6.99733C12.3266 4.05285 9.93919 1.66666 6.99471 1.66666C4.05023 1.66666 1.66285 4.05285 1.66137 6.99733C1.6599 9.9418 4.0449 12.3304 6.98937 12.3333ZM7.66671 11H6.33337V9.66666H7.66671V11ZM7.66671 8.99999H6.33337C6.3123 8.13177 6.76413 7.32052 7.51337 6.88133C7.95337 6.54399 8.33337 6.25332 8.33337 5.66666C8.33337 4.93028 7.73642 4.33332 7.00004 4.33332C6.26366 4.33332 5.66671 4.93028 5.66671 5.66666H4.33337V5.60666C4.34409 4.65395 4.86226 3.77933 5.69269 3.31226C6.52312 2.84519 7.53964 2.85662 8.35935 3.34226C9.17907 3.82789 9.67743 4.71395 9.66671 5.66666C9.61897 6.386 9.23315 7.04016 8.62671 7.42999C8.07851 7.77412 7.7232 8.3552 7.66671 8.99999Z"
        fill="#9D9D9C"
      />
    </svg>
  )
}

export default Help
