import React, { useContext, useEffect, useMemo } from 'react'
import { Routes, Route } from 'react-router-dom'
import { AuthError } from '../Login'
import Context from 'util/context'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'
import CustomerChat from 'util/customerChat'
import { getToken } from '../../util/storageData'
import { useLocation } from 'react-router'

const Login = React.lazy(() => import('components/Login'))
const Logout = React.lazy(() => import('components/User/Logout'))
const Pages = React.lazy(() => import('components/Pages'))
const Subscribers = React.lazy(() => import('components/Subscribers'))
const Notifications = React.lazy(() => import('components/Notifications'))
const ThankYou = React.lazy(() => import('components/Notifications/ThankYou'))
const Help = React.lazy(() => import('components/StaticPage/Help'))
const PrivacyPolicy = React.lazy(() => import('components/StaticPage/PrivacyPolicy'))
const Terms = React.lazy(() => import('components/StaticPage/Terms'))
const NoMatch = React.lazy(() => import('components/Main/NoMatch'))
const FaqClients = React.lazy(() => import('components/FaqClients/FaqClients'))
const FaqSellers = React.lazy(() => import('components/FaqSellers/FaqSellers'))

function Main() {
  const { isLoading, authError, notificationsSent, loaderContent, canSubscribe, user } =
    useContext(Context)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (authError) {
      navigate('/auth-error')
    }
  }, [authError])

  useEffect(() => {
    if (location.pathname === '/' && !canSubscribe && user) {
      navigate('/settings')
    }
  }, [user, canSubscribe])

  const pageLoader = useMemo(() => {
    if (isLoading) {
      return loaderContent
    }

    return null
  }, [isLoading])

  return (
    <>
      {pageLoader}
      <main className="page-main">
        <Routes>
          <Route
            index
            element={<React.Suspense>{getToken() ? <Subscribers /> : <Login />}</React.Suspense>}
          />
          <Route
            path="/send-notifications"
            element={
              <React.Suspense>
                <Notifications />
              </React.Suspense>
            }
          />
          <Route
            path="/settings"
            element={
              <React.Suspense>
                <Pages />
              </React.Suspense>
            }
          />
          <Route
            path="/auth/facebook/connect/check"
            element={
              <React.Suspense>
                <Login />
              </React.Suspense>
            }
          />
          <Route
            path="/auth-error"
            element={
              <React.Suspense>
                <AuthError />
              </React.Suspense>
            }
          />
          <Route
            path="/logout"
            element={
              <React.Suspense>
                <Logout />
              </React.Suspense>
            }
          />
          <Route
            path="/help"
            element={
              <React.Suspense>
                <Help />
              </React.Suspense>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <React.Suspense>
                <PrivacyPolicy />
              </React.Suspense>
            }
          />
          <Route
            path="/terms"
            element={
              <React.Suspense>
                <Terms />
              </React.Suspense>
            }
          />
          <Route
            path="/faq-dla-klientow"
            element={
              <React.Suspense>
                <FaqClients />
              </React.Suspense>
            }
          />
          <Route
            path="/faq-dla-sprzedawcow"
            element={
              <React.Suspense>
                <FaqSellers />
              </React.Suspense>
            }
          />
          {!!notificationsSent && (
            <Route
              path="/thank-you"
              element={
                <React.Suspense>
                  <ThankYou />
                </React.Suspense>
              }
            />
          )}
          <Route
            path="*"
            element={
              <React.Suspense>
                <NoMatch />
              </React.Suspense>
            }
          />
        </Routes>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </main>
      <CustomerChat />
    </>
  )
}

export default Main
