import styles from './user.module.css'

const STATUS_INACTIVE_ID = 0
const STATUS_ACTIVE_ID = 1
const STATUS_ENABLED_ID = 2
const STATUS_INACTIVE_TXT = 'Nieaktywny'
const STATUS_ACTIVE_TXT = 'Wył.'
const STATUS_ENABLED_TXT = 'Wł.'

function PageItem(page, index) {
  const status = page.status
    ? page.isReminderModeEnabled && page.isWebhookEnabled
      ? {
          statusId: STATUS_ENABLED_ID,
          label: STATUS_ENABLED_TXT
        }
      : {
          statusId: STATUS_ACTIVE_ID,
          label: STATUS_ACTIVE_TXT
        }
    : {
        statusId: STATUS_INACTIVE_ID,
        label: STATUS_INACTIVE_TXT
      }

  return (
    <div
      key={page.fbPageId}
      className={`${styles.facebookProfileMenuListItem} ${
        status.statusId === STATUS_ENABLED_ID ? styles.facebookProfileMenuListItemActive : ''
      }`}>
      <span className={styles.facebookProfileMenuListItemName}>
        {index + 1}. {page.name}
      </span>
      <span className={styles.facebookProfileMenuListItemStatus}>{status.label}</span>
    </div>
  )
}

export default PageItem
