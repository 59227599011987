import * as ACTION_TYPES from '../actions/action_type'

export const initialState = {
  user: null,
  isLoading: false,
  authError: false
}

export const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case ACTION_TYPES.SET_USER:
      return {
        ...state,
        user: action.user
      }
    case ACTION_TYPES.SET_AUTH_ERROR:
      return {
        ...state,
        authError: action.authError
      }
    default:
      return state
  }
}
