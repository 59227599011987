import { useState } from 'react'
import styles from './faq.module.css'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactHtmlParser from 'react-html-parser'
import Chevron from 'components/Main/Icons/Chevron'

function Faq({ items = [], withTwoColumns = false, listContainerClassName }) {
  const [activeItem, setActiveItem] = useState(null)

  return (
    <>
      <ul
        className={classNames(
          styles.faqAccordionList,
          {
            [styles.faqAccordionListWithTwoColumns]: withTwoColumns
          },
          listContainerClassName
        )}>
        {items.map((entry, index) => (
          <li key={`accordion${index}`}>
            <div
              className={classNames(styles.faqAccordion, {
                [styles.faqAccordionActive]: activeItem === `accordion${index}` || withTwoColumns,
                [styles.faqAccordionButtonGradient]:
                  activeItem === `accordion${index}` || withTwoColumns
              })}>
              <button
                onClick={() =>
                  setActiveItem(activeItem === `accordion${index}` ? null : `accordion${index}`)
                }
                className={styles.faqAccordionButton}>
                <span className={styles.faqAccordionButtonTitle}>{entry.title}</span>
                {!withTwoColumns && <Chevron className={styles.faqAccordionButtonIcon} />}
              </button>

              <div className={styles.faqAccordionPanel}>
                <p>{ReactHtmlParser(entry.content)}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

Faq.propTypes = {
  items: PropTypes.array,
  withTwoColumns: PropTypes.bool,
  listContainerClassName: PropTypes.string
}

export default Faq
