import { getToken, setToken } from 'util/storageData'

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL
const ERROR_INVALID_CREDENTIALS = 'Nieprawidłowe uprawnienia. Zaloguj się ponownie!'
const ERROR_UNKNOWN_EXCEPTION =
  'Wystąpił nieoczekiwany błąd. Odśwież stronę i spróbuj ponownie, bądź skontaktuj się z supportem.'
const ERROR_PAGE_ALREADY_EXISTS =
  'Strona jest już aktywowana! Odśwież stronę, bądź skontaktuj się z supportem.'
const ERROR_PAGE_ALREADY_DISABLED = 'Strona jest już wyłączona!'
const ERROR_PAGE_ALREADY_ENABLED = 'Strona jest już włączona!'
const ERROR_INVALID_ARGUMENT =
  'Błąd aplikacji. Nieprawidlowe dane! Odśwież stronę i spróbuj ponownie, bądź skontaktuj się z supportem.'
const ERROR_NOT_FOUND =
  'Błąd wewnętrzny aplikacji. Odśwież stronę i spróbuj ponownie, bądź skontaktuj się z supportem.'
const ERROR_PAGE_BUSY = 'Strona jest teraz przetwarzana. Spróbuj później.'
const ERROR_PAGE_SENDER_LIMIT = 'Błąd. Limit powiadomień został osiągnięty!'
const ERROR_NOTIFICATIONS_SENDER_LIMIT = ERROR_PAGE_SENDER_LIMIT
const ERROR_SUBSCRIBER_UNKNOWN_TYPE = 'Błąd. Typ wiadomości jest nieobsługiwany!'
const ERROR_SUBSCRIBER_LIMIT_EXCEPTION = 'Błąd. Przekroczony limit wysyłki powiadomień!'
const ERROR_FORBIDDEN =
  'Błąd. Brak dostępu do zasobu. Odśwież stronę i spróbuj ponownie, bądź skontaktuj się z supportem.'
const ERROR_LOGIN_INVALID_CREDENTIALS = 'Błąd. Podane dane logowania nie są prawidłowe!'
const ERROR_USER_NOT_ACTIVATED =
  'Błąd. Użytkownik nie został aktywowany. Sprawdź swoją skrzynkę e-mail lub skontaktuj się z obsługą biore.pl'
export const ERROR_NO_FACEBOOK_PERMISSIONS = 'Błąd. Użytkownik nie ma dostępu do konta Facebook.'

const getHeaders = (auth) => {
  const headers = {
    'Content-Type': 'application/json'
  }

  if (auth) {
    const token = getToken()
    headers['Authorization'] = `Bearer ${token}`
  }

  return headers
}

const throwError = (responseText) => {
  if (responseText) {
    const errorData = JSON.parse(responseText)
    throw new Error(getErrorWithTitle(errorData.errors.title))
  }

  throw new Error(ERROR_UNKNOWN_EXCEPTION)
}

const getError = (title) => {
  if (!title) {
    return ERROR_UNKNOWN_EXCEPTION
  }

  if (
    /App.Domain.Shared.Query.Exception.NotFoundException/gim.test(title) ||
    /App.Domain.Auth.Exception.InvalidCredentialsException/gim.test(title)
  ) {
    return ERROR_LOGIN_INVALID_CREDENTIALS
  }

  if (/App.Domain.User.Exception.UserIsNotActivatedException/gim.test(title)) {
    return ERROR_USER_NOT_ACTIVATED
  }

  if (/InvalidArgumentException/gim.test(title)) {
    return ERROR_INVALID_ARGUMENT
  }

  if (/NotFoundException/gim.test(title)) {
    return ERROR_NOT_FOUND
  }

  if (/ForbiddenException/gim.test(title)) {
    return ERROR_FORBIDDEN
  }

  if (/InvalidCredentialsException/gim.test(title)) {
    return ERROR_INVALID_CREDENTIALS
  }

  if (
    /UserFacebookAccessTokenMissingPermissionException/gim.test(title) ||
    /UserFacebookInvalidAccessTokenException/gim.test(title) ||
    /UserFacebookIdIsEmptyException/gim.test(title) ||
    /UserFacebookPageInvalidAccessTokenException/gim.test(title)
  ) {
    window.regenerateToken()
    return ERROR_INVALID_CREDENTIALS
  }

  if (/UserFacebookPageAlreadyExistsException/gim.test(title)) {
    return ERROR_PAGE_ALREADY_EXISTS
  }

  if (
    /UserFacebookPageReminderModeAlreadyDisabledException/gim.test(title) ||
    /UserFacebookPageWebhookAlreadyDisabledException/gim.test(title) ||
    /UserFacebookPageReminderModeIsDisabledException/gim.test(title)
  ) {
    return ERROR_PAGE_ALREADY_DISABLED
  }

  if (/UserFacebookPageSubscriberSenderReminderLimitException/gim.test(title)) {
    return ERROR_NOTIFICATIONS_SENDER_LIMIT
  }

  if (
    /UserFacebookPageReminderModeAlreadyEnabledException/gim.test(title) ||
    /UserFacebookPageSellModeAlreadyEnabledException/gim.test(title) ||
    /UserFacebookPageWebhookAlreadyEnabledException/gim.test(title)
  ) {
    return ERROR_PAGE_ALREADY_ENABLED
  }

  if (/UserFacebookPageReminderLockedException/gim.test(title)) {
    return ERROR_PAGE_BUSY
  }

  if (/UserFacebookPageSubscriberSenderLimitException/gim.test(title)) {
    return ERROR_PAGE_SENDER_LIMIT
  }

  if (/UserFacebookPageSubscriberUnknownMessageTypeException/gim.test(title)) {
    return ERROR_SUBSCRIBER_UNKNOWN_TYPE
  }

  if (/UserFacebookPageSubscriberSenderLimitException/gim.test(title)) {
    return ERROR_SUBSCRIBER_LIMIT_EXCEPTION
  }

  return ERROR_UNKNOWN_EXCEPTION
}

const getErrorWithTitle = (title) => {
  return `${getError(title)}<small>${title ? title : ''}</small>`
}

export const getUserData = async () => {
  const response = await fetch(`${BASE_API_URL}/user`, {
    method: 'GET',
    headers: getHeaders(true)
  })
  const responseData = await response.json()

  if (response.ok) {
    if (!responseData.data.attributes.facebookId) {
      throw new Error(ERROR_NO_FACEBOOK_PERMISSIONS)
    }

    const appData = {
      id: responseData.data.id,
      fullName: responseData.data.attributes.fullName,
      avatarUuid: responseData.data.attributes?.avatar?.uuid,
      userFacebookId: responseData.data.attributes.facebookId
    }

    appData.pages = (await getUserPagesList(appData)) || []
    appData.avatarUrl = getAvatarUrl(appData)

    return appData
  }

  //expired JWT token
  if (response.status === 401) {
    window.regenerateToken()
    return
  }

  if (responseData && responseData.errors) {
    throw new Error(getErrorWithTitle(responseData.errors.title))
  }

  throw new Error(ERROR_UNKNOWN_EXCEPTION)
}

export const forceConnectFbPagesData = async (appData) => {
  const fbPages = await getFbPagesList(appData)
  if (fbPages) {
    fbPages.forEach((fbPage) => {
      const userPageIndex = appData.pages.findIndex((page) => fbPage.fbPageId === page.fbPageId)
      if (userPageIndex > -1) {
        appData.pages[userPageIndex].name = fbPage.name
        appData.pages[userPageIndex].hasFbPage = fbPage.hasFbPage
        return
      }

      appData.pages.push({
        ...fbPage,
        isReminderModeEnabled: false,
        isWebhookEnabled: false,
        status: false
      })
    })
  }
  appData.fbPagesConnected = true

  return appData
}

export const auth = async (code, isFbReloadAction) => {
  const response = await fetch(`${BASE_API_URL}/auth/facebook/reminderLogin`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      code,
      isFbReloadAction
    })
  })
  const responseText = await response.text()

  if (response.ok) {
    const authData = JSON.parse(responseText)
    const token = authData.token
    setToken(token)
    return token
  }

  throwError(responseText)
}

export const getAvatarUrl = (userData) =>
  `${BASE_API_URL}/user/${userData.id}/media-show/${userData.avatarUuid}.jpg`

export const getFbPagesList = async (userData) => {
  if (userData.userFacebookId) {
    const response = await fetch(
      `${BASE_API_URL}/user/${userData.id}/facebook/${userData.userFacebookId}/page`,
      {
        method: 'GET',
        headers: getHeaders(true)
      }
    )
    const responseText = await response.text()
    if (response.ok) {
      const responseData = JSON.parse(responseText)
      return responseData.data.map(({ id, name }) => ({
        fbPageId: id,
        name,
        hasFbPage: true
      }))
    }

    throwError(responseText)
  } else {
    throwError()
  }
}

export const getUserPagesList = async (userData) => {
  const response = await fetch(`${BASE_API_URL}/user/${userData.id}/facebookPage`, {
    method: 'GET',
    headers: getHeaders(true)
  })

  const responseText = await response.text()
  if (response.ok) {
    const responseData = JSON.parse(responseText)
    return responseData.data.map(
      ({
        data: {
          id,
          attributes: { fbPageId, name, isReminderModeEnabled, isWebhookEnabled }
        }
      }) => ({
        uuid: id,
        hasFbPage: false,
        name: name ?? id,
        fbPageId,
        isReminderModeEnabled,
        isWebhookEnabled,
        status: true
      })
    )
  }

  throwError(responseText)
}

export const postActivateFbPage = async (fbPageId, userId) => {
  const response = await fetch(`${BASE_API_URL}/user/${userId}/facebookPage/reminderMode`, {
    method: 'POST',
    headers: getHeaders(true),
    body: JSON.stringify({
      fbPageId
    })
  })

  const responseText = await response.text()
  if (response.ok) {
    const pageData = JSON.parse(responseText)

    return pageData
  }

  throwError(responseText)
}

export const patchActivateFbPage = async (uuid, userId) => {
  const response = await fetch(`${BASE_API_URL}/user/${userId}/facebookPage/${uuid}/reminderMode`, {
    method: 'PATCH',
    headers: getHeaders(true)
  })

  if (response.ok) {
    return true
  }

  const responseText = await response.text()

  throwError(responseText)
}
export const ACTION_DISABLE_PAGE = 'DISABLE'
export const ACTION_ENABLE_PAGE = 'ENABLE'

export const disableOrEnableFbPage = async (pageId, userId, action) => {
  const method = action === ACTION_DISABLE_PAGE ? 'DELETE' : 'PATCH'
  const headers = getHeaders(true)
  const response = await fetch(`${BASE_API_URL}/user/${userId}/facebookPage/${pageId}/webhook`, {
    method,
    headers
  })

  if (response.ok) {
    return true
  }

  const responseText = await response.text()
  throwError(responseText)
}

export const removeFbPage = async (userId, userFacebookPageUuid) => {
  const response = await fetch(
    `${BASE_API_URL}/user/${userId}/facebookPage/${userFacebookPageUuid}`,
    {
      method: 'DELETE',
      headers: getHeaders(true)
    }
  )

  if (response.ok) {
    return true
  }

  const responseText = await response.text()
  throwError(responseText)
}

export const getFbPageSubscriber = async (user, fbPageId, searchSettings) => {
  const page = (user.pages || []).find((page) => fbPageId === page.fbPageId)
  const url = searchSettings.searchQuery
    ? `${BASE_API_URL}/user/${user.id}/facebookPage/${page.uuid}/subscriber/search?page=${searchSettings.currentPage}&limit=${searchSettings.perPage}&query=${searchSettings.searchQuery}`
    : `${BASE_API_URL}/user/${user.id}/facebookPage/${page.uuid}/subscriber?page=${searchSettings.currentPage}&limit=${searchSettings.perPage}`

  const response = await fetch(url, {
    method: 'GET',
    headers: getHeaders(true)
  })
  const responseText = await response.text()

  if (response.ok) {
    return JSON.parse(responseText)
  }

  throwError(responseText)
}

export const removeFbPageSubscriber = async (user, fbPageId, subscriberUuid) => {
  const fbPage = user.pages.find((fbPage) => fbPageId === fbPage.fbPageId)
  const response = await fetch(
    `${BASE_API_URL}/user/${user.id}/facebookPage/${fbPage.uuid}/subscriber/${subscriberUuid}`,
    {
      method: 'DELETE',
      headers: getHeaders(true)
    }
  )

  if (response.ok) {
    return true
  }

  const responseText = await response.text()
  throwError(responseText)
}

let fileName = 'subskrybenci.xlsx'
const downloadXls = (data) => {
  var fakeUrl = document.createElement('a')
  fakeUrl.href = URL.createObjectURL(data)
  fakeUrl.download = fileName
  fakeUrl.click()
}

export const getSubscibersXls = async (user, fbPageId) => {
  const fbPage = user.pages.find((fbPage) => fbPageId === fbPage.fbPageId)
  fileName = `subskrybenci-${fbPage.uuid}.xlsx`
  const response = await fetch(
    `${BASE_API_URL}/user/${user.id}/facebookPage/${fbPage.uuid}/subscriber/sheetFile`,
    {
      method: 'GET',
      headers: getHeaders(true)
    }
  )
  if (response.ok) {
    const blob = await response.blob()
    downloadXls(blob)
    return true
  }

  const responseText = await response.text()
  throwError(responseText)
}

export const sendNotifications = async (user, pageId, formData) => {
  const response = await fetch(
    `${BASE_API_URL}/user/${user.id}/facebookPage/${pageId}/subscriber/publish`,
    {
      method: 'PATCH',
      body: JSON.stringify(formData),
      headers: getHeaders(true)
    }
  )

  if (response.ok) {
    return true
  }

  const responseText = await response.text()
  throwError(responseText)
}

export const loginViaBiore = async (formData) => {
  const response = await fetch(`${BASE_API_URL}/auth`, {
    method: 'POST',
    body: JSON.stringify({
      _password: formData.password,
      _username: formData.email
    }),
    headers: getHeaders()
  })

  const responseText = await response.text()

  if (response.ok) {
    const authData = JSON.parse(responseText)
    const token = authData.token

    setToken(token)
    return token
  }

  throwError(responseText)
}
