/* eslint-disable react/prop-types */
import classNames from 'classnames'
import { forwardRef, useState } from 'react'

import iconPassword from '../../static/images/password-icon.svg'
import iconPasswordHide from '../../static/images/password-hide-icon.svg'
import styles from './input.module.css'

const InputInner = (props, ref) => {
  const {
    name,
    value,
    placeholder,
    onChange,
    onBlur,
    onFocus,
    infoMessage,
    icon,
    type = 'text',
    isDisabled = false,
    hasError = false
  } = props
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const isPasswordType = type === 'password'

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.inputContainer, styles.actionInputContainer, {
          [styles.borderError]: hasError
        })}>
        <input
          ref={ref}
          value={value}
          className={classNames(styles.input, {
            [styles.iconInput]: Boolean(icon || isPasswordType)
          })}
          type={isPasswordVisible ? 'text' : type}
          id={name}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={isDisabled}
          placeholder={placeholder}
        />
        {icon || isPasswordType ? (
          <button
            type="button"
            onClick={() => setPasswordVisible((value) => !value)}
            className={styles.showPasswordBtn}>
            <img src={isPasswordVisible ? iconPasswordHide : iconPassword} />
          </button>
        ) : null}
      </div>
      {infoMessage ? <span className={styles.message}>{infoMessage}</span> : null}
    </div>
  )
}

export default forwardRef(InputInner)
