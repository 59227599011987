import { useEffect } from 'react'
import { initFacebookSdk } from './initFacebookSdk'

function CustomerChat() {
  const chatRun = () => {
    const chatElement = document.getElementById('customer-chat')
    if (chatElement) {
      chatElement.setAttribute('page_id', process.env.REACT_APP_FACEBOOK_CHAT_PAGE_ID)
      chatElement.setAttribute('attribution', 'biz_inbox')
      window.FB.CustomerChat.show(true)
    }
  }

  useEffect(() => {
    initFacebookSdk(chatRun)
  }, [initFacebookSdk, chatRun])

  return <div id="customer-chat" className="fb-customerchat"></div>
}

export default CustomerChat
