import React, { useCallback, useEffect, useReducer, useState } from 'react'
import Context from 'util/context'
import * as ACTIONS from 'store/actions/actions'
import * as AppReducer from 'store/reducers/appReducer'
import App from './App'
import { clearToken, getToken } from 'util/storageData'
import { Puff } from 'react-loader-spinner'
import { forceConnectFbPagesData, getUserData, ERROR_NO_FACEBOOK_PERMISSIONS } from './util/api'
import { toast } from 'react-toastify'
import jwt_decode from 'jwt-decode'

const ContextState = () => {
  const [stateAppReducer, dispatchAppReducer] = useReducer(
    AppReducer.AppReducer,
    AppReducer.initialState
  )
  const [forceRefresh, setForceRefresh] = useState(true)
  const [canSubscribe, setCanSubscribe] = useState(false)
  const [notificationsSent, setNotificationsSent] = useState(false)
  const [isFbReloadAction, setIsFbReloadAction] = useState(false)
  const [currentFbPage, setCurrentFbPage] = useState(null)
  const [pagesLoading, setPagesLoading] = useState('')

  const setUser = async (data) => {
    if (data) {
      await resolveCanSubscribe(data)
    }
    dispatchAppReducer(ACTIONS.setUser(data))
    setForceRefresh(true)
  }

  const setIsLoading = (data) => {
    dispatchAppReducer(ACTIONS.setIsLoading(data))
  }

  const setAuthError = (data) => {
    dispatchAppReducer(ACTIONS.setAuthError(data))
  }

  const logout = async () => {
    await clearToken()
    await setUser(null)
    await setCanSubscribe(false)
  }

  window.regenerateToken = async () => {
    await setIsLoading(false)
    await logout()
    setUser(null)
    await setIsFbReloadAction(true)
    await setAuthError(true)
  }

  const resolveCanSubscribe = useCallback(async (user) => {
    if (user) {
      const subscribeFlag =
        user &&
        user.pages &&
        user.pages.some(
          (entry) => entry.status && entry.isReminderModeEnabled && entry.isWebhookEnabled
        )

      await setCanSubscribe(subscribeFlag)
    }
  })

  const setContextData = useCallback(async () => {
    if (!forceRefresh) {
      return
    }
    await loadUserData()

    if (stateAppReducer.user) {
      await resolveCanSubscribe(stateAppReducer.user)
    }

    await setForceRefresh(false)
  }, [forceRefresh, stateAppReducer])

  useEffect(() => {
    setContextData()
  }, [setContextData])

  const connectFbPagesData = useCallback(async () => {
    if (!pagesLoading && stateAppReducer.user && !stateAppReducer.user.fbPagesConnected) {
      try {
        setPagesLoading(true)
        const newUser = await forceConnectFbPagesData(stateAppReducer.user)
        await setUser(newUser)
      } catch (e) {
        process.env.NODE_ENV === 'development' && console.error(e)
        toast.error(<div dangerouslySetInnerHTML={{ __html: e.message }}></div>)
      }
      setPagesLoading(false)
    }
  }, [stateAppReducer.user, setUser, pagesLoading, setPagesLoading])

  const checkUserToken = () => {
    const token = getToken()
    const decoded = jwt_decode(token)

    if (decoded && decoded.exp && parseInt(new Date().getTime() / 1000) > parseInt(decoded.exp)) {
      window.regenerateToken()
    }
  }

  const loadUserData = async () => {
    try {
      if (!getToken() || stateAppReducer.user || pagesLoading) {
        return
      }

      checkUserToken()
      await setPagesLoading(true)
      const userData = await getUserData()
      await setUser(userData)
    } catch (e) {
      process.env.NODE_ENV === 'development' && console.error(e)
      toast.error(<div dangerouslySetInnerHTML={{ __html: e.message }}></div>)

      if (e.message === ERROR_NO_FACEBOOK_PERMISSIONS) {
        window.regenerateToken()
      }
    }
    await setPagesLoading(false)
  }

  const loaderContent = (
    <div className={'pageLoader'}>
      <Puff
        height="80"
        width="80"
        radisu={1}
        color="#4fa94d"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  )

  return (
    <Context.Provider
      value={{
        isLoading: stateAppReducer.isLoading,
        user: stateAppReducer.user,
        authError: stateAppReducer.authError,
        setIsLoading,
        setUser,
        logout,
        canSubscribe,
        setNotificationsSent,
        notificationsSent,
        isFbReloadAction,
        setCurrentFbPage,
        currentFbPage,
        loaderContent,
        connectFbPagesData,
        pagesLoading,
        loadUserData,
        setForceRefresh
      }}>
      <App />
    </Context.Provider>
  )
}

export default ContextState
