import fbDefaultImg from 'static/images/facebook-image-default.jpg'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Context from 'util/context'
import ProfileStatus from 'components/Main/Icons/ProfileStatus'
import Fanpage from 'components/Main/Icons/Fanpage'
import Logout from 'components/Main/Icons/Logout'
import { Link } from 'react-router-dom'
import styles from './user.module.css'
import PageItem from './PageItem'
import { Puff } from 'react-loader-spinner'
import Help from 'components/Main/Icons/Help'

function UserTop() {
  const { user, connectFbPagesData, pagesLoading, canSubscribe } = useContext(Context)
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [showPagesMenu, setShowPagesMenu] = useState(false)

  useEffect(() => {
    if (showPagesMenu) {
      connectFbPagesData()
    }
  }, [showPagesMenu, connectFbPagesData])

  const isActive = useMemo(() => {
    if (!(user && user.pages && user.pages.length)) {
      return null
    }

    const { pages } = user
    return pages.some((item) => item.status) && canSubscribe
  }, [user, canSubscribe])

  const disableMenu = useCallback(
    (event) => {
      if (
        !event.target.closest('[data-el=userMenu]') ||
        event.target.closest('[data-el=userMenuLinks]')
      ) {
        setShowUserMenu(false)
      }

      if (event.target.closest('[data-el=fanpageMenuList]')) {
        setShowPagesMenu(true)
        return
      }

      if (!event.target.closest('[data-el=fanpageMenu]')) {
        setShowPagesMenu(false)
      }
    },
    [setShowUserMenu, setShowPagesMenu]
  )

  useEffect(() => {
    document.body.addEventListener('click', disableMenu)

    return () => {}
  }, [disableMenu])

  if (!user) {
    return null
  }

  const { pages } = user
  const additionalClass = isActive ? styles.facebookProfileActive : styles.facebookProfileInactive

  return (
    <div className={`${styles.facebookProfile} ${additionalClass}`}>
      <div
        data-el={'fanpageMenu'}
        onClick={() => setShowPagesMenu(!showPagesMenu)}
        className={`${styles.facebookProfileStatus} 
          ${showPagesMenu ? styles.facebookProfileStatusActive : ''}`}>
        <ProfileStatus />
        <span className={styles.facebookProfileStatusInfo}>
          {isActive ? 'Aktywne' : 'Nieaktywne'}
        </span>
        <div
          className={`${styles.facebookProfileMenuList} ${
            showPagesMenu ? styles.facebookProfileMenuListActive : ''
          }`}>
          {!pagesLoading && pages ? (
            <div data-el={'fanpageMenuList'} className={styles.facebookProfileMenuListInner}>
              {pages.map((page, index) => PageItem(page, index))}
            </div>
          ) : (
            <div className={styles.pagesLoader}>
              <Puff
                height="20"
                width="20"
                radisu={1}
                color="#4fa94d"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
          <Link to={'/settings'} className="btn-settings">
            Przejdź do ustawień
          </Link>
        </div>
      </div>

      <div data-el={'userMenu'} className={styles.facebookProfileSettings}>
        <a
          className={`${styles.userMenu} ${showUserMenu ? styles.userMenuActive : ''}`}
          onClick={() => setShowUserMenu(!showUserMenu)}>
          <img src={user.avatarUrl ?? fbDefaultImg} alt="" />
          <span className={styles.facebookProfileSettingsSpan}>{user.fullName}</span>
        </a>
        <div
          data-el={'userMenuLinks'}
          className={`${styles.facebookProfileSettingsMenuList} ${
            showUserMenu ? styles.facebookProfileSettingsMenuListActive : ''
          }`}>
          <Link to="/settings" className={styles.facebookProfileSettingsMenuListItem}>
            <Fanpage />
            <span>Twoje fanpage</span>
          </Link>
          <Link to="/faq-dla-sprzedawcow" className={styles.facebookProfileSettingsMenuListItem}>
            <Help />
            <span>FAQ Sprzedawcy</span>
          </Link>
          <Link to="/logout" className={styles.facebookProfileSettingsMenuListItem}>
            <Logout />
            <span>Wyloguj mnie</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default UserTop
